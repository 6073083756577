import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, IconButton, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashbrdHeader from './DashbrdHeader';
import Sidebar from './Sidebar';

function Layout() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box component='div'>
      <Box sx={{ display: 'flex', minHeight: '100vh', overflow: 'hidden' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: 'none' }, position: 'fixed', top: 7, left: 15, zIndex: 1300, color:"#fff" }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        </Box>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 210 },
          }}
        >
          <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        </Drawer>
        <Box sx={{ flexGrow: 1, marginLeft: { sm: '210px' }, overflowY: 'auto' }}>
          <Box position='sticky' top={0} zIndex={10}>
            <DashbrdHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
          </Box>
          <Box  minHeight='100vh' textAlign='left'>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
