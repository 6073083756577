import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Typography, TextField, Button, Grid, IconButton } from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import axios from 'axios';
import { activate_employee, get_all_employees_attendance_data, get_deactivate_employee } from '../Api/Api';
import { useEffect } from 'react';
import { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import '../App.css'
import SnackBar from '../Components/SnackBar';

const rows = [
];

export default function DeactivateEmployeePage() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [timeFilter, setTimeFilter] = React.useState('all');
    const [data, setData] = useState([]);
    const fileName = 'Attendance data';
    const [empname, setEmpname] = useState('')
    const [totalPages, setTotalPages] = useState(0);

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const keys = [
        'employeeId',
        'firstName',
        'mobile',
        'designation',
        'email',
        'department',
    ];



    const fetchDeactivateList = (pageNumber) => {
        const sendData = new FormData();
        sendData.append("dataPerPage", rowsPerPage)
        sendData.append("pageNo", pageNumber + 1)
        sendData.append("employeeName", empname)
        axios({
            method: 'POST',
            url: get_deactivate_employee,
            data: sendData,
        }).then((res) => {
            if (!res.data.error) {
                setData(res.data.data)
                setTotalPages(res.data.totalCount)
                setOpen(true);
                setMessage(res.data.message)
                setColor(true);
                setStatus(true);
            } else {
                setData([]);
                setColor(false)
                setOpen(true);
                setStatus(false);
                setMessage(res.data.message)
            }
        }).catch((err) => {
            alert("Oops something went wrong" + err)
        })
    }


    useEffect(() => {
        fetchDeactivateList(page);
    }, [page, rowsPerPage])

    const handleActivate = (id) => {
        const sendData = new FormData();
        sendData.append("employeeId", id)
        console.log("id", id)
        axios({
            method: 'POST',
            url: activate_employee,
            data: sendData,
        }).then((res) => {
            if (!res.data.error) {
                setMessage(res.data.message);
                setOpen(true);
                setColor(true);
                setStatus(true);
                fetchDeactivateList(page);
            } else {
                setMessage(res.data.message);
                setOpen(true);
                setColor(false);
                setStatus(false);
            }
        }).catch((err) => {
            alert("Oops something went wrong", + err)
        })
    }


    const handleSearch = () => {
        fetchDeactivateList(page);
    }
    const handleClear = () => {
        setEmpname("");
    }


    return (
        <Box sx={{ alignItems: 'center', marginTop: '70px', px:4, py:2 }}>
            <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '600', fontSize: '25px', flexGrow: 1, color:"#0165A1" }}>Deactivate Employee</Typography>
            </Box>


            <Box>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={6} sm={6} xs={12} >
                        <TextField
                            fullWidth
                            label="Employee Name"
                            type='text'
                            variant="outlined"
                            size='small'
                            color='secondary'
                            sx={{ my: 2 }}
                            value={empname}
                            onChange={(e) => setEmpname(e.target.value)}
                        />
                    </Grid>

                    <Grid item lg={1.5} md={6} sm={6} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Button className='attendeaseButton' onClick={handleClear} sx={{ width: '100%', my: 2 }}>clear</Button>
                    </Grid>
                    <Grid item lg={1.5} md={6} sm={6} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Button className='attendeaseButton' onClick={handleSearch} sx={{ width: '100%', my: 2 }} >search</Button>
                    </Grid>
                </Grid>




            </Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ border: '1px solid silver' }}>
                    <Table>
                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#0165A1' }}>
                            <TableRow>
                                {keys.map((key) => (
                                    <TableCell sx={{ color: 'white', fontWeight: 500, textAlign: 'center' }} key={key}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                                                {key.split(/(?=[A-Z])/).join(' ')}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                ))}
                                <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center', textTransform: 'uppercase' }}>Approve</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && data?.map((row, index) => (
                                    <TableRow key={index}>
                                        {keys.map((key) => (
                                            <TableCell align="center" key={key}>
                                                {key === 'checkIn' || key === 'checkOut' ? (
                                                    new Date(row[key]).toLocaleString()
                                                ) : key === 'totalHours' ? (
                                                    `${row[key]} hours`
                                                ) : key === 'day' ? (
                                                    row[key] === 'Today' || row[key] === 'Yesterday' ? (
                                                        <Typography sx={{ fontWeight: 'bold' }}>{row[key]}</Typography>
                                                    ) : (
                                                        row[key]
                                                    )
                                                ) : (
                                                    row[key]
                                                )}
                                            </TableCell>
                                        ))}
                                        <TableCell align="center">
                                            <IconButton aria-label="activate" onClick={() => handleActivate(row.employeeId)}>
                                                <CheckCircleOutlineIcon style={{color:'green'}} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    count={totalPages}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}