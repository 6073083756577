import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, TextField, IconButton, Button, Dialog, DialogTitle, DialogContent, Grid, DialogActions, TablePagination } from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Password } from '@mui/icons-material';
import { add_employee, deactivate_employee, get_all_employees_attendance_data, get_attendance_notification, get_employee, methodPost } from '../Api/Api';
import axios from 'axios';
import { useEffect } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SnackBar from '../Components/SnackBar';



function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
];

export default function DashBoardPage() {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredRows, setFilteredRows] = useState(rows);
    const [filteredData, setFilteredData] = React.useState([]);
    const [firsName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [number, setNumber] = useState('');
    const [designation, setDesignation] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [department, setDepartment] = useState('');

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);


    const keys = [
        'Employee Name',
        'Department',
        'Designation',
        'Total Days',
    ];


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const fetchEmployee = () => {
        axios({
            method: 'GET',
            url: get_attendance_notification,
        }).then((res) => {
            if (!res.data.error) {
                setData(res.data.data)
            } else {
                setData([]);
            }
        }).catch((err) => {
            alert("Oops something went wrong" + err)
        })
    }

    useEffect(() => {
        fetchEmployee();
    }, [])

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);



    return (
        <Box sx={{ alignItems: 'center', marginTop: '70px', px: 4, pt: 2 }}>
            <Typography variant="h4" sx={{fontWeight:"600"}}>Welcome</Typography>
            {/* <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
            <Typography sx={{ fontWeight: '600', fontSize: '25px', pb: 2, color: "#0165A1" }}>Current Month</Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ border: '1px solid silver' }}>
                    <Table>
                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#0165A1' }}>
                            <TableRow>
                                {keys.map((key) => (
                                    <TableCell sx={{ color: 'white', fontWeight: 500, textAlign: 'center' }} key={key}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                                                {key.split(/(?=[A-Z])/).join(' ')}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead> */}
                        {/* <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow key={row.employeeId}>
                <TableCell align="center">{row.firstName}</TableCell>
                <TableCell align="center">{row.designation}</TableCell>
                <TableCell align="center">{row.messages}</TableCell>
                <TableCell align="center">{row.date}</TableCell>
              </TableRow>
            ))}
            {rowsPerPage > 0 && data.length > 0 && (
              <TableRow style={{ height: 53 * (rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)) }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody> */}
                        {/* <TableBody>
                            <TableRow >
                                <TableCell align="center">Raghu</TableCell>
                                <TableCell align="center">It</TableCell>
                                <TableCell align="center">Associate Web Developer</TableCell>
                                <TableCell align="center">3</TableCell>
                            </TableRow>
                        </TableBody> */}
                    {/* </Table> */}
                {/* </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    count={data.length}
                />
            </Paper> */}
        </Box>
    );
}
