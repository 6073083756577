import React from 'react';
import { SidebarData } from './SidebarData';
import { useLocation, useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleMove = (link) => {
    navigate(`/layout/${link}`);
  };

  return (
    <div className='Sidebar'>
      <ul className='sidebarul'>
        {SidebarData.map((val, key) => {
          return (
            <li key={key}
              id={location.pathname === `/layout/${val.link}` ? "active" : ""}
              onClick={() => handleMove(val.link)}
              className='sidebarli'
            >
              <div className='sideicon'>{val.icon}</div>
              <div className='sidetitle'>{val.title}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Sidebar;
