import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, TextField, IconButton, Button, Dialog, DialogTitle, DialogContent, Grid, DialogActions, TablePagination } from '@mui/material';
// import '../css/EmployeeList.css';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Password } from '@mui/icons-material';
import { add_employee, deactivate_employee, get_all_employees_attendance_data, get_employee, methodPost, update_employee } from '../Api/Api';
import axios from 'axios';
import { useEffect } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import { set } from 'react-hook-form';
import SnackBar from '../Components/SnackBar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));





function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
];

export default function CustomizedTables() {
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredRows, setFilteredRows] = useState(rows);
  const [filteredData, setFilteredData] = React.useState([]);
  const [firsName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [number, setNumber] = useState('');
  const [designation, setDesignation] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [department, setDepartment] = useState('');
  const [updateFirstName, setUpdateFirstName] = useState('');
  const [updateLastName, setUpdateLastName] = useState('');
  const [updateDesignation, setUpdateDesignation] = useState('');
  const [updateEmail, setUpdateEmail] = useState('');
  const [employeelist, setEmployeeList] = useState([]);
  const [updateDepartment, setUpdateDepartment] = useState('');
  const [employeeId, setEmployeeId] = useState('');

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleAddEmployee = () => {
    setOpenDialog(true);
  };
  const handleEditEmployee = (id) => {
    const sendData = new FormData()
    sendData.append("employeeId", id)
    axios({
      method: methodPost,
      url: get_employee,
      data: sendData
    })
      .then((res) => {
        if (!res.data.status) {
          setEmployeeList(res.data.data)
          setEmployeeId(id)
          console.log('empid', id)
          setUpdateFirstName(res.data.data.firstName)
          setUpdateLastName(res.data.data.lastName)
          setUpdateDesignation(res.data.data.designation)
          setUpdateEmail(res.data.data.email)
          setUpdateDepartment(res.data.data.department)
          setOpenEditDialog(true)
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);

        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };

  const keys = [
    'Employee Name',
    'Mobile Number',
    'Position',
    'Edit',
    'Deactivate',

  ];

  const handleSaveEmployee = () => {
    const sendData = new FormData()
    sendData.append("firstName", firsName)
    sendData.append("lastName", lastName)
    sendData.append("mobile", number)
    sendData.append("designation", designation)
    sendData.append("email", email)
    sendData.append("userPassword", password)
    sendData.append("department", department)
    axios({
      method: methodPost,
      url: add_employee,
      data: sendData
    })
      .then((res) => {
        if (!res.data.status) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          handleClose();
          fetchEmployee()
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };

  const handleClose = () => {
    setOpenDialog(false);
  }
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveEditEmployee = () => {
    const sendData = new FormData()
    sendData.append("employeeId", employeeId)
    sendData.append("firstName", updateFirstName)
    sendData.append("firstName", updateFirstName)
    sendData.append("lastName", updateLastName)
    sendData.append("designation", updateDesignation)
    sendData.append("email", updateEmail)
    sendData.append("department", updateDepartment)
    axios({
      method: methodPost,
      url: update_employee,
      data: sendData
    })
      .then((res) => {
        if (!res.data.status) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          handleEditClose();
          fetchEmployee();
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };

  const handleEditClose = () => {
    setOpenEditDialog(false);
  }
  const handleEditCloseDialog = () => {
    setOpenEditDialog(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleDeactivate = (id) => {
    const sendData = new FormData();
    sendData.append("employeeId", id)
    console.log("employeeid", id)
    axios({
      method: 'POST',
      url: deactivate_employee,
      data: sendData,
    }).then((res) => {
      if (!res.data.error) {
        setMessage(res.data.message);
        setOpen(true);
        setColor(true);
        setStatus(true);
        fetchEmployee();
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setColor(false);
        setStatus(false);
      }
    }).catch((err) => {
      alert("Oops something went wrong", + err)
    })
  }

  const fetchEmployee = () => {
    const sendData = new FormData();
    sendData.append("dataPerPage", 600)
    sendData.append("pageNo", 1)
    axios({
      method: 'POST',
      url: get_employee,
      data: sendData,
    }).then((res) => {
      if (!res.data.error) {
        setData(res.data.data)
      } else {
        setData([]);
      }
    }).catch((err) => {
      alert("Oops something went wrong" + err)
    })
  }

  useEffect(() => {
    fetchEmployee();
  }, [])

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);



  return (
    <Box className="PageFit" sx={{ alignItems: 'center', marginTop: '80px', px:3, }}>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: '600', fontSize: '25px', color:'#0165A1' }}>Employee List</Typography>
          <Button className='attendeaseButton' onClick={handleAddEmployee} >Add Employee</Button>
        </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', paddingBottom: '20px' }}>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Add Employeee</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="First name"
                  sx={{ my: 1 }}
                  value={firsName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="Last name"
                  sx={{ my: 1 }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="Mobile Number"
                  sx={{ my: 1 }}
                  value={number}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[0-9]*$/;
                    if (regex.test(input)) {
                      setNumber(e.target.value)
                    }
                  }}

                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="Designation"
                  sx={{ my: 1 }}
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="Email"
                  sx={{ my: 1 }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="User Password"
                  sx={{ my: 1 }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="Department"
                  sx={{ my: 1 }}
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSaveEmployee}>Add</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openEditDialog} onClose={handleEditCloseDialog}>
          <DialogTitle>Add Employeee</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="First name"
                  sx={{ my: 1 }}
                  value={updateFirstName}
                  onChange={(e) => setUpdateFirstName(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="Last name"
                  sx={{ my: 1 }}
                  value={updateLastName}
                  onChange={(e) => setUpdateLastName(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="Designation"
                  sx={{ my: 1 }}
                  value={updateDesignation}
                  onChange={(e) => setUpdateDesignation(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="Email"
                  sx={{ my: 1 }}
                  value={updateEmail}
                  onChange={(e) => setUpdateEmail(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField
                  label="Department"
                  sx={{ my: 1 }}
                  value={updateDepartment}
                  onChange={(e) => setUpdateDepartment(e.target.value)}
                  fullWidth
                  size='small'
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditCloseDialog}>Cancel</Button>
            <Button onClick={handleSaveEditEmployee}>Update</Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ border: '1px solid silver' }}>
          <Table>
            <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#0165A1' }}>
              <TableRow>
                {keys.map((key) => (
                  <TableCell sx={{ color: 'white', fontWeight: 500, textAlign: 'center' }} key={key}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                        {key.split(/(?=[A-Z])/).join(' ')}
                      </Box>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : data
              ).map((row) => (
                <TableRow key={row.employeeId}>
                  <TableCell align="center">{`${row.firstName} ${row.lastName}`}</TableCell>
                  <TableCell align="center">{row.mobile}</TableCell>
                  <TableCell align="center">{row.designation}</TableCell>
                  <TableCell align="center">
                    <IconButton aria-label="delete" onClick={() => handleEditEmployee(row.employeeId)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton aria-label="delete" onClick={() => handleDeactivate(row.employeeId)}>
                      <HighlightOffIcon style={{ color: 'red' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {rowsPerPage > 0 && data.length > 0 && (
                <TableRow style={{ height: 53 * (rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)) }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={data.length}
        />
      </Paper>
    </Box>
  );
}
