import React, { useState } from 'react';
import '../Css/Pages.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { admin_login } from '../Api/Api';
import { Form } from 'react-bootstrap';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SnackBar from '../Components/SnackBar';
import LoginImage from '../Image/login-image.jpg'

export default function LoginPage() {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [data, setData] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const sendData = new FormData();
        sendData.append("adminEmail", email)
        sendData.append("adminPassword", password)
        axios({
            method: 'POST',
            url: admin_login,
            data: sendData,
        }).then((res) => {
            if (!res.data.status) {
                setData(res.data.data)
                localStorage.setItem("adminId", res.data.data.adminId)
                console.log("adminId", res.data.data.adminId)
                setTimeout(() => {
                    navigate(`/layout/dashboard`);
                }, 600);
                setMessage(res.data.message);
                setOpen(true);
                setColor(true);
                setStatus(true);
            } else {
                setMessage(res.data.message);
                setOpen(true);
                setColor(false);
                setStatus(false);
            }
        }).catch((err) => {
            alert("Opps something went wrong" + err);
        },)
    }


    return (
        <div className="login-container">
             <SnackBar
            open={open}
            message={message}
            setOpen={setOpen}
            status={status}
            color={color}
        />
            <div className="login-left">
                <div className="login-illustration">
                    <img src={LoginImage} alt="login-image" width={"100%"} />
                </div>
            </div>
            <div className="login-right">
                <h2>Hello Again!</h2>
                <p>Welcome back you've been missed!</p>
                <Form className='formLogin' onSubmit={handleSubmit} >
                    <TextField
                        className="signin-textfield"
                        margin="normal"
                        required
                        fullWidth
                        value={email}
                        label="User Name"
                        variant="outlined"
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#0165A1',
                            },
                        }}


                    />
                    <TextField
                        className='login-textfield'
                        sx={{ width: '100%', mb:1 }}
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        type={showPassword ? '' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        autoComplete='off'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityIcon style={{ color: '#0165A1' }} /> : <VisibilityOffIcon style={{ color: '#0165A1' }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <button type="submit" className="sign-in-button">Sign In</button>
                </Form>
            </div>
        </div>
    );
}
