import {  Navigate, useRoutes } from 'react-router-dom'
import Home from './Pages/Home';
import Layout from './Components/Layout';
import AttendanceListPage from './Pages/AttendanceList';
import DeactivateEmployeePage from './Pages/DeactivateEmployee';
import AddLocationPage from './Pages/AddLocation';
import NotificationPage from './Pages/Notification';
import LoginPage from './Pages/Login';
import DashBoardPage from './Pages/DashBoard';

export const startUrl = `/`;


function Routes() {


    return useRoutes([
    {
    path: startUrl,
    element: <LoginPage />
    },
    {
    path:'*',
    element: <Navigate to="/" />
    },
    {
    path:'/layout',
    element: <Layout />,
    children:[
    {
    path:'home',
    element: <Home />
    },
    {
    path:'attendance',
    element: <AttendanceListPage />
    },
    {
    path:'deactivate',
    element: <DeactivateEmployeePage />
    },
    {
    path:'location',
    element: <AddLocationPage />
    },
    {
    path:'notification',
    element: <NotificationPage />
    },
    {
    path:'dashboard',
    element: <DashBoardPage />
    },
    ]},
    ])
}

export default Routes;