import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Typography, TextField, Button, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import axios from 'axios';
import { get_all_employees_attendance_data } from '../Api/Api';
import { useEffect, useState } from 'react';
import SnackBar from '../Components/SnackBar';
import { ExportButton } from '../Components/ExportButton';
import '../App.css'

const columns = [
  { id: 'firstName', label: 'Employee Name', minWidth: 100 },
  { id: 'designation', label: 'Position', minWidth: 170, align: 'right' },
  { id: 'checkIn', label: 'In-Time', minWidth: 170, align: 'right' },
  { id: 'checkOut', label: 'Out-Time', minWidth: 170, align: 'right' },
  { id: 'totalHours', label: 'Total Hours', minWidth: 170, align: 'right' },
  { id: 'attendanceDate', label: 'Attendance Date', minWidth: 170, align: 'right' },
];

export default function AttendanceListPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);
  const fileName = 'Attendance data';
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [empname, setEmpname] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState('');

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filteredName, setFilteredName] = useState('');

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterChange = (e) => {
    setFilteredName(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchAttendanceList = (pageNumber) => {
    const sendData = new FormData();
    sendData.append('dataPerPage', rowsPerPage);
    sendData.append('pageNo', pageNumber + 1);
    sendData.append('employeeName', filteredName || empname);
    sendData.append('fromDate', fromDate);
    sendData.append('toDate', toDate);
    axios({
      method: 'POST',
      url: get_all_employees_attendance_data,
      data: sendData,
    })
      .then((res) => {
        if (!res.data.error) {
          setData(res.data.data);
          setTotalPages(res.data.totalCount);
          setOpen(true);
          setMessage(res.data.message);
          setColor(true);
          setStatus(true);
        } else {
          setData([]);
          setColor(false);
          setOpen(true);
          setStatus(false);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        alert('Oops something went wrong' + err);
      });
  };

  useEffect(() => {
    fetchAttendanceList(page);
  }, [page, rowsPerPage, filteredName]);


  
  const handleSearch = () => {
    if (!empname || !fromDate || !toDate ) {
      setMessage('Please select both start and end dates.');
      setOpen(true);
      setColor(false);
      setStatus(false);
    } else if (new Date(fromDate) > new Date(toDate)) {
      setMessage('Start date cannot be greater than end date.');
      setOpen(true);
      setColor(false);
      setStatus(false);
    } else {
      fetchAttendanceList(page);
    }
  };

  const handleClear = () => {
    setEmpname('');
    setFilteredName('');
    setFromDate('');
    setToDate('');
  };

  return (
    <Box sx={{ alignItems: 'center', marginTop: '70px', px: 4, py: 2 }}>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: '600', fontSize: '25px', flexGrow: 1, color: "#0165A1" }}>Attendance List</Typography>
        <ExportButton data={data} fileName={fileName} />
      </Box>

      <Box>
        <Grid container spacing={1}>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <TextField
              fullWidth
              label="Employee Name"
              type="text"
              variant="outlined"
              size="small"
              color="secondary"
              sx={{ my: 2 }}
              value={empname}
              onChange={(e) => setEmpname(e.target.value)}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              variant="outlined"
              size="small"
              color="secondary"
              sx={{ my: 2 }}
              value={fromDate}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <TextField
              fullWidth
              label="To Date"
              type="date"
              variant="outlined"
              size="small"
              color="secondary"
              sx={{ my: 2 }}
              value={toDate}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Grid>
          <Grid item lg={1.5} md={6} sm={6} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button className="attendeaseButton" onClick={handleClear} sx={{ width: '100%', my: 2 }}>
              Clear
            </Button>
          </Grid>
          <Grid item lg={1.5} md={6} sm={6} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button className="attendeaseButton" onClick={handleSearch} sx={{ width: '100%', my: 2 }}>
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ border: '1px solid silver' }}>
          <Table>
            <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#0165A1' }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} sx={{ color: 'white', fontWeight: 500, textAlign: 'center' }}>
                    {column.label}
                    {column.id === 'firstName' && (
                      <>
                        <IconButton onClick={handleFilterClick}>
                          <FilterListIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Menu
                          anchorEl={filterAnchorEl}
                          open={Boolean(filterAnchorEl)}
                          onClose={handleFilterClose}
                        >
                          <MenuItem>
                            <TextField
                              fullWidth
                              label="Filter Name"
                              type="text"
                              variant="outlined"
                              size="small"
                              value={filteredName}
                              onChange={handleFilterChange}
                            />
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{row.firstName}</TableCell>
                  <TableCell align="center">{row.designation}</TableCell>
                  <TableCell align="center">{row.checkIn}</TableCell>
                  <TableCell align="center">{row.checkOut}</TableCell>
                  <TableCell align="center">{row.totalHours}</TableCell>
                  <TableCell align="center">{row.attendanceDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 15, 25, 50]}
          component="div"
          count={totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
