// const apibaseUrl = `https://mdqualityapps.in/API/attendease/development/`;

const apibaseUrl = ` https://mdqualityapps.in/API/attendease/sriradio/`;




const methodGet = 'GET';
const methodPost = 'POST';


const admin_login = `${apibaseUrl}admin_login`;

const add_location = `${apibaseUrl}add_location`;

const add_employee = `${apibaseUrl}add_employee`;

const get_employee = `${apibaseUrl}get_employee`;

const get_all_employees_attendance_data = `${apibaseUrl}get_all_employees_attendance_data`;

const deactivate_employee = `${apibaseUrl}deactivate_employee`;

const get_deactivate_employee = `${apibaseUrl}get_deactivate_employee`;

const activate_employee = `${apibaseUrl}activate_employee`;

const update_employee = `${apibaseUrl}update_employee`;

const get_attendance_notification = `${apibaseUrl}get_attendance_notification`;


export {
    methodGet,
    methodPost,
    admin_login,
    add_location,
    add_employee,
    get_all_employees_attendance_data,
    get_employee,
    deactivate_employee,
    get_deactivate_employee,
    activate_employee,
    update_employee,
    get_attendance_notification
};