import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { methodPost, add_location } from '../Api/Api'
import axios from 'axios'
import { useForm } from "react-hook-form";
import NearMeIcon from '@mui/icons-material/NearMe';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import SnackBar from "../Components/SnackBar";


export default function AddLocationPage () {

  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [place, setPlace] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const adminId = localStorage.getItem("adminId")


  const handleSubmit = () => {
    const sendData = new FormData();
    sendData.append("adminId", adminId);
    sendData.append("latitude", latitude);
    sendData.append("longitude", longitude);
    sendData.append("place", place);
    axios({
      method:"POST",
      url:add_location,
      data:sendData,
    }).then((res)=>{
      if(!res.data.status){
        setMessage(res.data.message)
        setOpen(true);
        setColor(true);
        setStatus(true);
        setLatitude("")
        setLongitude("")
        setPlace("")
      }else{
        setMessage(res.data.message);
        setOpen(true);
        setColor(false);
        setStatus(false);
      }
    }).catch((err)=>{
      alert("Oops something went wrong" + err);
    })
  }

  return (
    <Box sx={{ alignItems: 'center', marginTop: '70px', px:4, pt:2 }}>
      <SnackBar
            open={open}
            message={message}
            setOpen={setOpen}
            status={status}
            color={color}
        />
      <Typography sx={{ fontWeight: '600', fontSize: '25px', color:"#0165A1" }}> Add Location </Typography>
      <Card></Card>
      <Grid container sx={{ paddingTop: '5%', paddingLeft: '5%', paddingRight: '5%'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card sx={{ padding: '5%', borderRadius: '5px', boxShadow:" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }} >
            <Typography sx={{ fontWeight: '600', fontSize: '20px', color: '#0165A1', display: 'flex', alignItems: 'center' }}>
              Add Your Location <GpsFixedIcon sx={{ marginLeft: '5px' }} />
            </Typography>
            <Box>
              <Form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="Latitude"
                        type='text'
                        variant="outlined"
                        size='small'
                        color='secondary'
                        sx={{ my: 2 }}
                        value={latitude}
                        onChange={(e) => {
                          const input = e.target.value;
                          const regex = /^[0-9]*\.?[0-9]*$/;
                          if (regex.test(input)){ setLatitude(e.target.value) }
                        }}

                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="Longitude"
                        type='text'
                        variant="outlined"
                        size='small'
                        color='secondary'
                        sx={{ my: 2 }}
                        value={longitude}
                        onChange={(e) => {
                          const input = e.target.value;
                          const regex = /^[0-9]*\.?[0-9]*$/;
                          if (regex.test(input)){ setLongitude(e.target.value) }
                        }}

                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="Place"
                        type='text'
                        variant="outlined"
                        size='small'
                        color='secondary'
                        sx={{ my: 2 }}
                        value={place}
                        onChange={(e) => setPlace(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        width:"100%",
                        mt: 2, mb: 1,
                        fontWeight: '600',
                        backgroundColor: '#0265A4'
                      }}
                    >
                      SUbmit<NearMeIcon sx={{ paddingLeft: '7px' }} />
                    </Button>
                    </Grid>

                  </Grid>
              </Form>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}