import React from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ChecklistIcon from '@mui/icons-material/Checklist';
import BlockIcon from '@mui/icons-material/Block';
import PlaceIcon from '@mui/icons-material/Place';
import DashboardIcon from '@mui/icons-material/Dashboard';
export const SidebarData = [
    {
        title:"Dash Board",
        icon:<DashboardIcon />,
        link:"dashboard"
    },
    {
        title:"Employee List",
        icon:<FormatListBulletedIcon />,
        link:"home"
    },
    {
        title:"Attendance List",
        icon:<ChecklistIcon />,
        link:"attendance"
    },
    {
        title:"Deactivate Employee",
        icon:<BlockIcon />,
        link:"deactivate"
    },
    {
        title:"Add Location",
        icon:<PlaceIcon />,
        link:"location"
    }
]