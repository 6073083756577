import { Box,  Button,  Card, Typography, AppBar, IconButton, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
// import ResponsiveBd from '../../Components/ResponsiveBd';
import logo from '../Image/mdq-white.png';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

// eslint-disable-next-line react/prop-types, no-unused-vars
function DashbrdHeader({ mobileOpen, setMobileOpen }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/');
    localStorage.removeItem("adminId")
  }
  const handleNotification = () => {
    navigate('/layout/notification');
  }

    return(
    <AppBar sx={{boxShadow:'none'}}>
    <Box component={Card} borderBottom='1px solid rgba(0, 0,0,0.1)' bgcolor='#3B82F6' boxShadow='none' display='flex' justifyContent='space-between' alignContent='center' square={true}>

    <Box sx={{ textAlign: 'center', pl:4, display:'flex', alignItems:'center' }}>
    <img  src={logo} alt="logo"
    style={{ objectFit: 'contain', height: '50px', width:'100%', verticalAlign:'top'}} loading='lazy' />
    </Box>

      <Box display='flex' justifyContent='end' gap={2} px={2} alignContent='center' alignItems='center' square={true}>
          <Tooltip title="Notifications">
        <IconButton onClick={handleNotification}>
          <NotificationsActiveIcon style={{ color: "#fff" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Logout">
        <IconButton onClick={handleLogout}>
          <PowerSettingsNewIcon style={{ color: "#fff" }} />
        </IconButton>
      </Tooltip>

      </Box>
    </Box>
    </AppBar>
    )
}

export default DashbrdHeader;