import React from 'react';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportButton = ({ data, fileName }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = () => {
        // Create a workbook
        const wb = XLSX.utils.book_new();

        // Create a sheet for the attendance data
        const ws = XLSX.utils.json_to_sheet(data);

        // Add the sheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Attendance Data');

        // Generate the Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const excelData = new Blob([excelBuffer], { type: fileType });

        // Save the file using FileSaver.js
        FileSaver.saveAs(excelData, fileName + fileExtension);
    };

    return (
        <Button
            variant="contained"
            onClick={exportToCSV}
            startIcon={<ExitToAppIcon />}
            sx={{ my: 1 }}
        >
            Export
        </Button>
    );
};
